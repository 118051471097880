import React, {Component} from "react"
import {Model} from "Model"
import {Gate} from "lib/gate/Gate"
import styles from "./DocPage.module.css"
import {computed, observable, runInAction} from "mobx"
import {observer} from "mobx-react"
import {SignaturePopup} from "components/SignaturePopup"
import {Button} from "./parts/button"
import {api_response, DocumentStatus} from "lib/gate/interfaces"
import {BackButton} from "./parts/BackButton"
import {KlassDropdown} from "components/KlassDropdown"
import {DropdownOption} from "components/Dropdown"
import {ChangeStatusPopup} from "./parts/ChangeStatusPopup"

interface Props {
    model: Model
    gate: Gate
    document_id: number
}

const statuses: DropdownOption<DocumentStatus>[] = [
    {label: "Not Ready", value: "not ready"},
    {label: "Ready to Send", value: "ready to send"},
    {label: "Sent to Student", value: "sent to student"},
    {label: "Viewed by Student", value: "viewed by student"},
    {label: "Pending Approval", value: "pending approval"},
    {label: "Completed", value: "completed"}
]

@observer
export class DocPage extends Component<Props> {
    private iframe: HTMLIFrameElement

    @observable
    private isLoading = false

    @observable
    private showSignaturesPopup: boolean = false

    @observable
    private showInitialsPopup: boolean = false

    @observable
    private changeStatusPopup: {visible: boolean; new_status: DocumentStatus} = {
        visible: false,
        new_status: undefined
    }

    @observable
    private pack: api_response.Pack

    @observable
    private document: api_response.GenericDocument

    @observable
    private saveButtonEnabled = false

    @observable
    private readyToSendButtonEnabled = true

    @observable error: string = null

    public async componentDidMount() {
        window.addEventListener("message", this.listener.bind(this))
        this.loadDocument()
    }

    private async loadDocument() {
        const {document_id, gate} = this.props

        let r = await gate.request(`/docs/${document_id}/get`)
        if (!r.success) {
            this.error = r.errors[0].message
            return
        }
        const document = r.data

        const {data} = await gate.request(`/packs/${document.pack_id}/get`)

        runInAction(() => {
            this.document = document
            this.pack = data
        })
    }

    @computed
    private get iframe_url(): string {
        const doc = this.document
        if (!doc) {
            return ""
        }
        const token = this.props.gate.getToken()
        if (doc.type === "set1") {
            return `${process.env.REACT_APP_SET1_DOMAIN}/docs/${doc.id}?token=${token}`
        }
        if (doc.type === "agreement") {
            return `${process.env.REACT_APP_AGREEMENT_DOMAIN}/docs/${doc.id}?token=${token}`
        }
        if (doc.type === "plan") {
            return `${process.env.REACT_APP_PLAN_DOMAIN}/docs/${doc.id}?token=${token}`
        }
    }

    @computed
    private get title(): string {
        const doc = this.document
        if (!doc) {
            return ""
        }
        if (doc.type === "set1") {
            return "Other FA Documents"
        }
        if (doc.type === "agreement") {
            return "Enrollment Agreement"
        }
        if (doc.type === "plan") {
            return "Financial Plan"
        }
    }

    public render() {
        if (this.error) {
            return <h1>{this.error}</h1>
        }

        const visitor = this.props.model.visitor

        const isStaff: boolean = visitor.role === "staff" || visitor.role === "admin"

        const {model, gate} = this.props
        let name
        let college_id = ""
        let backURL = ""
        if (this.document && this.document.user) {
            const user = this.document.user
            name = user.first_name + " " + user.last_name
            college_id = user.college_id
            backURL = `/users/${user.id}?token=${gate.getToken()}`
        }

        return (
            <>
                {this.showSignaturesPopup && (
                    <SignaturePopup
                        mode="signature"
                        model={model}
                        gate={gate}
                        onClose={this.onSignaturePopupClose.bind(this)}
                    />
                )}
                {this.showInitialsPopup && (
                    <SignaturePopup
                        mode="initials"
                        model={model}
                        gate={gate}
                        onClose={this.onSignaturePopupClose.bind(this)}
                    />
                )}
                {this.changeStatusPopup.visible && (
                    <ChangeStatusPopup
                        gate={gate}
                        document_id={this.document.id}
                        old_status={this.document.status}
                        new_status={this.changeStatusPopup.new_status}
                        onClose={() => {
                            this.changeStatusPopup = {visible: false, new_status: undefined}
                            this.loadDocument()
                        }}
                    />
                )}

                <div
                    style={{
                        boxSizing: "border-box",
                        width: "1023px",
                        height: "165px",
                        margin: "auto",
                        position: "relative"
                    }}>
                    <div style={{position: "absolute", right: "20px", top: "10px", fontSize: "13px"}}>
                        You are: {visitor.first_name} {visitor.last_name} ({visitor.email} &nbsp;
                        <strong> {visitor.role}</strong>)
                    </div>
                    <section className={styles.header}>
                        <header className={styles.nameWrapper}>
                            <BackButton url={backURL} />
                            <h1 className={styles.title}>
                                {name} <span className={styles.college_id}>(Student ID: {college_id})</span>
                            </h1>
                        </header>
                        <p className={styles.text}>
                            {this.pack && this.pack.name}
                            {this.pack && " - "}
                            {this.title}
                            {"   "}
                            <a
                                href={`${this.iframe_url}&pdf`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{display: "inline"}}>
                                (PDF)
                            </a>
                        </p>

                        <div className={styles.buttonWrapper}>
                            <div className={styles.buttonContainer}>
                                <Button onClick={this.onSignatureClick.bind(this)} label="My signature" />
                            </div>
                            <div className={styles.buttonContainer}>
                                <Button onClick={this.onInitialsClick.bind(this)} label="My initials" />
                            </div>
                            <div className={styles.buttonContainer}>
                                <Button onClick={this.onNextClick.bind(this)} label=" Next signature" />
                            </div>

                            <div className={styles.buttonSaveGroup}>
                                {isStaff && (
                                    <Button
                                        onClick={this.onSave.bind(this)}
                                        label="Save"
                                        type="secondary"
                                        disabled={!this.saveButtonEnabled}
                                    />
                                )}
                                {/*{isStaff && (*/}
                                {/*    <Button*/}
                                {/*        onClick={() => {*/}
                                {/*            this.postMessage({command: "check_form_fields"})*/}
                                {/*        }}*/}
                                {/*        label="Ready To Send"*/}
                                {/*        type="secondary"*/}
                                {/*        disabled={!this.readyToSendButtonEnabled}*/}
                                {/*    />*/}
                                {/*)}*/}

                                {isStaff && (
                                    <div style={{width: "210px"}}>
                                        <KlassDropdown
                                            isDisabled={false}
                                            isMulti={false}
                                            isLoading={this.isLoading}
                                            options={statuses}
                                            value={statuses.filter((state) => {
                                                if (this.document && state.value === this.document.status) {
                                                    return true
                                                }
                                                return false
                                            })}
                                            onChange={async (item: DropdownOption<DocumentStatus>) => {
                                                if (this.document.status === item.value) {
                                                    return
                                                }

                                                this.changeStatusPopup = {visible: true, new_status: item.value}
                                                return
                                            }}
                                            placeholder="Set Status"
                                        />
                                    </div>
                                )}

                                {!isStaff && (
                                    <Button
                                        onClick={this.onSave.bind(this)}
                                        label="Save"
                                        type="secondary"
                                        disabled={!this.saveButtonEnabled}
                                    />
                                )}
                                {/*{!isStaff && (*/}
                                {/*    <Button*/}
                                {/*        onClick={this.onSaveAndSubmitToStaff.bind(this)}*/}
                                {/*        label="Save & Submit to staff"*/}
                                {/*        type="secondary"*/}
                                {/*    />*/}
                                {/*)}*/}

                                {!isStaff && (
                                    <Button
                                        onClick={() => {
                                            this.postMessage({command: "check_form_fields"})
                                        }}
                                        label="Submit to staff"
                                        type="secondary"
                                        disabled={!this.readyToSendButtonEnabled}
                                    />
                                )}
                            </div>
                        </div>
                    </section>
                </div>

                <div>
                    <iframe
                        title={this.title}
                        src={this.iframe_url}
                        frameBorder={0}
                        width="100%"
                        height="100%"
                        className={styles.iframe}
                        allowFullScreen
                        // ref={this.iframeRef}
                        ref={(f) => {
                            this.iframe = f as HTMLIFrameElement
                        }}
                    />
                </div>
            </>
        )
    }

    private async listener({data}) {
        if (
            data.source === "react-devtools-content-script" ||
            data.source === "react-devtools-bridge" ||
            data.source === "react-devtools-detector"
        ) {
            // Ignore message from dev tools
            return
        }
        try {
            data = JSON.parse(data)
        } catch (e) {}
        console.log("=======PLATFORM=====>>>>")
        console.log(data)
        console.log("=======PLATFORM=====<<<<")

        const command = data.command
        if (command === "showSignaturePopup") {
            this.showSignaturesPopup = true
        }
        if (command === "showInitialsPopup") {
            this.showInitialsPopup = true
        }

        if (command === "show_alert") {
            this.props.model.alert(data.message)
        }

        if (command === "enable_save_button") {
            this.saveButtonEnabled = true
        }

        if (command === "disable_save_button") {
            this.saveButtonEnabled = false
        }

        if (command === "disable_ready_to_send_button") {
            this.readyToSendButtonEnabled = false
        }

        if (command === "go_back") {
            this.props.model.getHistory().push(`/users/${this.props.model.user.id}?token=${this.props.gate.getToken()}`)
        }
    }

    private postMessage(data) {
        /*if (!this.iframeLoaded) {
            alert("Iframe not loaded yet!!!");
        }*/
        this.iframe.contentWindow.postMessage(data, "*")
    }

    private onSignatureClick() {
        console.log("S")
        this.showSignaturesPopup = true
    }

    private onInitialsClick() {
        console.log("S")
        this.showInitialsPopup = true
    }

    private onSignaturePopupClose() {
        this.showSignaturesPopup = false
        this.showInitialsPopup = false
        this.postMessage({command: "update_visitor"})
    }

    private onNextClick() {
        this.postMessage({command: "scroll_to_next_signature"})
    }

    private onSave() {
        this.postMessage({command: "save"})
    }

    private onSaveAndSubmitToStudent() {
        this.postMessage({command: "save_and_submit_to_student"})
    }

    private onSaveAndSubmitToStaff() {
        this.postMessage({command: "save_and_submit_to_staff"})
    }
}
