import React, {Component} from "react"
import ReactDOM from "react-dom"
import styles from "./NewDocumentPopup.module.css"
import {Model} from "Model"
import {Gate} from "lib/gate/Gate"
import {computed, observable} from "mobx"
import {observer} from "mobx-react"
import {StaffDropdown} from "components/StaffDropdown"
import {AgreementTypeDropdown} from "components/AgreementTypeDropdown"
import {api_response} from "lib/gate/interfaces"
import {ReactComponent as Icon} from "./parts/img/new-document.svg"
import {CloseButton} from "components/popupButtons/CloseButton"
import {CheckBox} from "./parts/check-box"
import {PopupButton} from "components/popupButtons/PopupButton"

interface Props {
    pack: api_response.Pack
    model: Model
    gate: Gate
    onClose: () => void
}

@observer
export class NewDocumentPopup extends Component<Props, {}> {
    @observable
    private type: api_response.DocumentType = "plan"

    @observable
    private agreementType: string

    @observable
    private staffId: number = null
    public componentDidMount() {
        document.getElementById("appModal").style.display = "block"
    }

    public componentWillUnmount() {
        document.getElementById("appModal").style.display = "none"
    }

    @computed
    private get isOkDisabled(): boolean {
        if (this.type === "plan" && this.staffId) {
            return false
        }

        if (this.type === "agreement" && this.staffId && this.agreementType) {
            return false
        }

        if (this.type === "set1" && this.staffId) {
            return false
        }

        return true
    }

    private async addAgreement(pack_id, staffId: number, agreement_type: string) {
        const data = {
            pack_id: pack_id,
            type: "agreement",
            domain: process.env.REACT_APP_AGREEMENT_DOMAIN,
            json_data: {},
            json_settings: {
                staff_ids: [staffId],
                agreement_type: agreement_type
            }
        }
        await this.props.gate.request("/docs/add", {data})
        await this.props.model.loadPacks()
        this.props.onClose()
    }

    private async addPlan(pack_id, staffId: number) {
        const data = {
            pack_id: pack_id,
            type: "plan",
            domain: process.env.REACT_APP_PLAN_DOMAIN,
            json_data: {},
            json_settings: {
                staff_ids: [staffId],
                settings_id: 1
            }
        }
        await this.props.gate.request("/docs/add", {data})
        await this.props.model.loadPacks()
        this.props.onClose()
    }

    private async addSet1(pack_id, staffId: number) {
        const data = {
            pack_id: pack_id,
            type: "set1",
            domain: process.env.REACT_APP_SET1_DOMAIN,
            json_data: {},
            json_settings: {
                staff_ids: [staffId]
            }
        }
        await this.props.gate.request("/docs/add", {data})
        await this.props.model.loadPacks()
        this.props.onClose()
    }

    public render() {
        const {onClose, model, pack} = this.props

        let html = (
            <article className={styles.popup}>
                <div className={styles.iconWrapper}>
                    <Icon className={styles.icon} width="39" height="39" />
                </div>
                <div className={styles.body}>
                    <div className={styles.closeButtonWrapper}>
                        <CloseButton onClick={this.props.onClose} />
                    </div>
                    <h1 className={styles.title}>Add Document</h1>

                    <div className={styles.checkBoxWrapper}>
                        <CheckBox
                            text="Financial Plan"
                            name="type"
                            checked={this.type === "plan"}
                            onChange={() => {
                                this.type = "plan"
                            }}
                        />

                        <CheckBox
                            text="Enrollment Agreement"
                            name="type"
                            checked={this.type === "agreement"}
                            onChange={() => {
                                this.type = "agreement"
                            }}
                        />

                        <CheckBox
                            text="Other FA Documents"
                            name="type"
                            checked={this.type === "set1"}
                            onChange={() => {
                                this.type = "set1"
                            }}
                        />
                    </div>
                    {/*<p className={styles.text}>This action cannot be undone</p>*/}
                    <br />
                    <div style={{width: "445px"}}>
                        <StaffDropdown
                            disabled={false}
                            staffUsers={model.staffUsers}
                            isLoading={model.staffUsersIsLoading}
                            selectedId={this.staffId}
                            onChange={(value: number) => {
                                this.staffId = value
                            }}
                            placeholder="Select School Official"
                        />
                    </div>
                    {this.type === "agreement" && (
                        <div style={{marginTop: "15px", width: "445px"}}>
                            <AgreementTypeDropdown
                                disabled={false}
                                agreementTypes={model.agreementTypes}
                                selectedType={this.agreementType}
                                onChange={(type: string) => {
                                    // @ts-ignore
                                    this.agreementType = type
                                }}
                            />
                        </div>
                    )}

                    <div className={styles.buttonWrapper}>
                        {onClose && <PopupButton text=" Cancel" onClick={onClose} />}
                        <PopupButton
                            text="OK"
                            type="secondary"
                            onClick={() => {
                                const pack_id = this.props.pack.id
                                const staff_id = this.staffId
                                if (this.type === "agreement") {
                                    this.addAgreement(pack_id, staff_id, this.agreementType)
                                }

                                if (this.type === "plan") {
                                    this.addPlan(pack_id, staff_id)
                                }

                                if (this.type === "set1") {
                                    this.addSet1(pack_id, staff_id)
                                }
                            }}
                            disabled={this.isOkDisabled}
                        />
                    </div>
                </div>
            </article>
        )

        return ReactDOM.createPortal(html, document.getElementById("appModal"))
    }
}
