import React, {Component} from "react"
import {observer} from "mobx-react"
import styles from "./table.module.css"
import {api_response} from "lib/gate/interfaces"
import {Preloader} from "./parts/preloader"
import {Gate} from "lib/gate/Gate"
import {Line} from "./parts/line"
import {Model} from "Model"
import {ContextButton} from "./parts/ContextButton"
import {SubMenu} from "./parts/SubMenu"
import {observable} from "mobx"
import {SendEmailPopup} from "../SendEmailPopup"
import {Button} from "components/Button"
import {NewDocumentPopup} from "../NewDocumentPopup"

const TABLE_STRUCTURE: Column[] = [
    "Document type",
    "Description",
    "College Admin",
    "Updated",
    "Status",
    "PDF",
    "Signature"
]

interface Props {
    gate: Gate
    model: Model
    pack: api_response.Pack
    isLoading: boolean
}

export type Column = "Document type" | "Description" | "College Admin" | "Updated" | "Status" | "PDF" | "Signature"

@observer
export class Table extends Component<Props, {}> {
    @observable
    private newDocumentPopup = false

    public render() {
        const tableStructure = TABLE_STRUCTURE
        const {pack, isLoading, gate, model} = this.props
        const isStaff = model.isStaff

        return (
            <div className={styles.tableWrapper}>
                {isLoading && <Preloader />}
                {this.newDocumentPopup && (
                    <NewDocumentPopup
                        pack={this.props.pack}
                        model={model}
                        gate={gate}
                        onClose={() => {
                            this.newDocumentPopup = false
                        }}
                    />
                )}

                <table className={styles.table} onClick={() => {}}>
                    <tbody>
                        <TableHeader tableStructure={tableStructure} gate={gate} model={model} pack={pack} />
                        {pack.docs.map((item, index) => {
                            return (
                                <Line
                                    key={item.id}
                                    document={item}
                                    tableStructure={tableStructure}
                                    gate={gate}
                                    model={model}
                                />
                            )
                        })}
                    </tbody>
                </table>

                {isStaff && (
                    <Button
                        text="ADD DOCUMENT"
                        type="primary"
                        onClick={() => {
                            this.newDocumentPopup = true
                        }}
                    />
                )}
            </div>
        )
    }
}

interface TableHeaderProps {
    pack: api_response.Pack
    gate: Gate
    model: Model
    tableStructure: Column[]
}
@observer
class TableHeader extends React.Component<TableHeaderProps, {}> {
    @observable
    private showContextMenu = false

    @observable
    private sendEmailPopup = false

    public render() {
        const {tableStructure, model, gate, pack} = this.props
        return (
            <>
                {this.sendEmailPopup && (
                    <SendEmailPopup
                        pack={this.props.pack}
                        model={model}
                        gate={gate}
                        onClose={() => {
                            this.sendEmailPopup = false
                        }}
                    />
                )}
                <tr className={styles.headerLine}>
                    {tableStructure.map((column: Column) => {
                        return (
                            <th className={styles.headerCell} key={column}>
                                <div className={styles.CellWrapper}>{column}</div>
                            </th>
                        )
                    })}
                    <th className={styles.headerCell}>
                        <div className={styles.CellWrapperBtn}>
                            <ContextButton
                                onClick={(e) => {
                                    this.showContextMenu = true
                                }}
                            />

                            {this.showContextMenu && (
                                <SubMenu
                                    onClose={() => {
                                        this.showContextMenu = false
                                    }}
                                    items={[
                                        {
                                            id: "send email",
                                            title: "send email",
                                            onClick: () => {
                                                this.sendEmailPopup = true
                                            }
                                        }
                                    ]}
                                />
                            )}
                        </div>
                    </th>
                </tr>
            </>
        )
    }
}
