import React, {Component} from "react"
import {api_response} from "lib/gate/interfaces"
import styles from "./DescriptionCell.module.css"

type Item = api_response.Agreement | api_response.Doc | api_response.Plan

interface Props {
    item: Item
}

export class DescriptionCell extends Component<Props, {}> {
    public render() {
        const {item} = this.props

        let content: string = ""
        if (item.type === "agreement") {
            content = item.json_settings.agreement_type
        }

        if (item.type === "set1") {
            content = "Other FA Documents"
        }

        if (item.type === "plan") {
            content = item.json_data.award_year || "?"
        }

        return <td className={styles.cell}> {content}</td>
    }
}
