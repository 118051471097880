import React, {Component} from "react"
import {observer} from "mobx-react"
import {computed} from "mobx"
import {DropdownOption} from "../Dropdown"
import {KlassDropdown} from "../KlassDropdown"

interface Props {
    disabled: boolean
    agreementTypes: string[]
    selectedType: string | null
    onChange: (type: string | null) => void
    styles?: object
}

@observer
export class AgreementTypeDropdown extends Component<Props, {}> {
    @computed
    private get options(): DropdownOption<string>[] {
        return this.props.agreementTypes.map((type: string) => {
            return {
                label: type,
                value: type
            }
        })
    }

    public render() {
        const {disabled, agreementTypes, onChange, selectedType} = this.props

        return (
            <KlassDropdown
                isDisabled={disabled}
                isMulti={false}
                isLoading={false}
                options={this.options}
                value={this.options.filter((el) => {
                    return el.value === selectedType
                })}
                onChange={(item) => {
                    const type = item.value
                    if (agreementTypes.indexOf(type) !== -1) {
                        onChange(type)
                        return
                    }

                    onChange(null)
                }}
                placeholder="Select Enrollment Agreement"
            />
        )
    }
}
