import React from "react"
import styles from "./check-box.module.css"

interface Props {
    text: string
    onChange: (event) => void
    name: string
    disabled?: boolean
    checked: boolean
}

export function CheckBox(props: Props) {
    const {checked, onChange, disabled, text} = props
    return (
        <label className={styles.label}>
            <input type="checkbox" checked={checked} onChange={onChange} disabled={disabled} className={styles.input} />
            <div className={styles.point}></div>
            {text}
        </label>
    )
}
