import React, {Component} from "react"
import {api_response} from "lib/gate/interfaces"
import styles from "./IconCell.module.css"
import {observer} from "mobx-react"
import {ReactComponent as DocIcon} from "./img/doc.svg"

type Item = api_response.Agreement | api_response.Doc | api_response.Plan

interface Props {
    item: Item
    token: string
    type: "pdf" | "signature"
}

@observer
export class IconCell extends Component<Props, {}> {
    private get domain() {
        const {item} = this.props
        if (item.type === "plan") return process.env.REACT_APP_PLAN_DOMAIN
        if (item.type === "agreement") return process.env.REACT_APP_AGREEMENT_DOMAIN
        if (item.type === "set1") return process.env.REACT_APP_SET1_DOMAIN
        return null
    }

    public render() {
        const {token, item, type} = this.props

        const pdf_url = `${this.domain}/docs/${item.id}?token=${token}&pdf`
        const signature_url = `${this.domain}/docs/${item.id}/certificate?pdf&token=${token}`
        let url = ""
        if (type === "pdf") url = pdf_url
        if (type === "signature") url = signature_url
        return (
            <td className={styles.cell}>
                <div className={styles.wrapper}>
                    <a href={url} target="_blank" className={styles.link}>
                        <DocIcon width="24" height="24" />
                    </a>
                </div>
            </td>
        )
    }
}
