import React, {Component} from "react"
import {observer} from "mobx-react"

import {Button} from "components/Button"
import {Package} from "./parts/package"
import {Model} from "Model"
import {Gate} from "lib/gate/Gate"

import styles from "./FinAid.module.css"
import {api_response, User} from "lib/gate/interfaces"

interface Props {
    user_id: number
    model: Model
    gate: Gate
}

@observer
export class FinAid extends Component<Props> {
    public async componentDidMount() {
        const model = this.props.model

        model.user = model.user || ({id: this.props.user_id} as User)
        model.loadUser(this.props.user_id)
        model.loadPacks()
        model.loadTypes()
    }

    private async onAddClick() {
        const data = {
            user_id: this.props.user_id,
            json_data: {}
        }
        await this.props.gate.request("/packs/add", {data})
        this.props.model.loadPacks()
    }

    public render() {
        const {model, gate} = this.props
        const user = model.user

        if (!user) {
            return "Loading ..."
        }

        const isStaff = model.isStaff

        const packages = model.packsList.map((pack: api_response.Pack) => (
            <Package gate={gate} model={model} pack={pack} key={pack.id} />
        ))
        return (
            <main className={styles.main}>
                <section className={styles.singlePackage}>
                    <h1 className={styles.singlePackage__title}>
                        {user.first_name} {user.last_name}{" "}
                        <span className={styles.college_id}> (Student ID: {user.college_id})</span>
                    </h1>
                    {packages}
                    {isStaff && <Button text="ADD PACKAGE" type="secondary" onClick={this.onAddClick.bind(this)} />}
                </section>
            </main>
        )
    }
}
