import React, {Component} from "react"
import ReactDOM from "react-dom"
import styles from "./SendEmailPopup.module.css"
import {api_response} from "lib/gate/interfaces"
import {Model} from "Model"
import {Gate} from "lib/gate/Gate"
import {computed, observable} from "mobx"
import {observer} from "mobx-react"
import {AxiosInstance} from "axios"
import {PopupButton} from "components/popupButtons/PopupButton"
import {ReactComponent as Icon} from "./parts/img/paper-plane.svg"
import {CloseButton} from "components/popupButtons/CloseButton"
import {Input} from "./parts/Input"
import {CheckBox} from "./parts/check-box"
import {SendButton} from "./parts/SendButton/SendButton"

const axiosModule = require("axios")

interface Props {
    pack: api_response.Pack
    model: Model
    gate: Gate
    onClose: () => void
}

@observer
export class SendEmailPopup extends Component<Props, {}> {
    private axios: AxiosInstance

    @observable
    private email: string

    @observable
    private secondary_email: string

    @observable
    private parent_email: string

    @observable
    private selectedDocuments: api_response.DocumentType[] = []

    @observable
    private selectedIds: number[] = []

    @observable
    private isLoading = false

    constructor(props) {
        super(props)

        // this.email = props.model.user.email
        this.email = "dmitry@klassapp.com"
        this.secondary_email = props.model.user.secondary_email

        this.axios = axiosModule.create()
    }

    @computed
    private get showParentEmail(): boolean {
        let selectedDocs = this.props.pack.docs.filter((doc) => {
            if (this.selectedIds.includes(doc.id)) {
                return true
            }
        })

        let hasCheckbox = selectedDocs.find((doc) => {
            if (doc && doc.json_data.enable_parent_role) {
                return true
            }
            return false
        })

        if (hasCheckbox) {
            return true
        }
        return false
    }

    public componentDidMount() {
        document.getElementById("appModal").style.display = "block"
    }

    public componentWillUnmount() {
        document.getElementById("appModal").style.display = "none"
    }

    private updateSelectedIds(checkboxValue: boolean, id: number) {
        if (checkboxValue && !this.selectedIds.includes(id)) {
            this.selectedIds.push(id)
        }

        if (!checkboxValue && this.selectedIds.includes(id)) {
            const index = this.selectedIds.indexOf(id)
            this.selectedIds.splice(index, 1)
        }
    }

    private getPreviewURLs() {
        const token = this.props.gate.getToken()
        const pack_id = this.props.pack.id
        const domain = process.env.REACT_APP_EMAIL_DOMAIN

        const doc_ids = this.selectedIds.join(",")

        const html_url = `${domain}/packs/${pack_id}/emails/to-student/html-body?doc_ids=${doc_ids}&token=${token}`
        const subject_url = `${domain}/packs/${pack_id}/emails/to-student/subject?doc_ids=${doc_ids}&token=${token}`
        const text_url = `${domain}/packs/${pack_id}/emails/to-student/text-body?doc_ids=${doc_ids}&token=${token}`
        return {subject_url, html_url, text_url}
    }

    private getParentPreviewURLs() {
        const token = this.props.gate.getToken()
        const pack_id = this.props.pack.id
        const domain = process.env.REACT_APP_EMAIL_DOMAIN

        const doc_ids = this.selectedIds.join(",")

        const html_url = `${domain}/packs/${pack_id}/emails/to-parent/html-body?doc_ids=${doc_ids}&token=${token}`
        const subject_url = `${domain}/packs/${pack_id}/emails/to-parent/subject?doc_ids=${doc_ids}&token=${token}`
        const text_url = `${domain}/packs/${pack_id}/emails/to-parent/text-body?doc_ids=${doc_ids}&token=${token}`
        return {subject_url, html_url, text_url}
    }

    private async onSendEmail() {
        let secondary_email
        // if (this.secondary_email && this.secondary_email.length > 3) {
        //     secondary_email = this.secondary_email
        // }

        const data = {
            doc_ids: this.selectedIds,
            email_object: await this.get_toStudentEmail(),
            email: this.email,
            secondary_email: secondary_email
        }

        let r = await this.props.gate.request(`/emails/send-to-student`, {data})

        this.props.onClose()
        if (r.success) {
            this.props.model.alert("Email has been sent")
            this.props.model.loadPacks()
        } else {
            this.props.model.alert("Error! Can not send email")
        }
    }

    private async get_toStudentEmail() {
        const {subject_url, html_url, text_url} = this.getPreviewURLs()
        let emailData = await Promise.all([
            this.axios.get(subject_url),
            this.axios.get(html_url),
            this.axios.get(text_url)
        ])
        const subject = emailData[0].data
        const html = emailData[1].data
        const text = emailData[2].data

        return {subject, html, text}
    }

    public render() {
        const {pack, model, gate, onClose} = this.props
        const user = model.user
        if (!user) {
            return null
        }

        const urls = this.getPreviewURLs()
        const {subject_url, html_url} = urls
        const parent_urls = this.getParentPreviewURLs()
        const {subject_url: parent_subject_url, html_url: parent_html_url} = parent_urls

        let html = (
            <article className={styles.popup}>
                <div className={styles.iconWrapper}>
                    <Icon className={styles.icon} width="40" height="40" />
                </div>
                <div className={styles.body}>
                    <div className={styles.closeButtonWrapper}>
                        <CloseButton onClick={onClose} />
                    </div>
                    <h1 className={styles.title}>
                        Email to {user.first_name} {user.last_name}
                    </h1>

                    <Input
                        title="Primary Email:"
                        placeholder="Enter email address"
                        value={this.email}
                        onChange={(e) => {
                            this.email = e.target.value
                        }}
                        onClear={() => {
                            this.email = ""
                        }}
                    />

                    <PreviewLinks html_url={html_url} subject_url={subject_url} />
                    <SendButton email={this.email} doc_ids={this.selectedIds} urls={urls} model={model} gate={gate} />
                    <div className={styles.margin}></div>
                    <Input
                        title="Secondary Email:"
                        value={this.secondary_email}
                        placeholder="Enter email address"
                        onChange={(e) => {
                            this.secondary_email = e.target.value
                        }}
                        onClear={() => {
                            this.secondary_email = ""
                        }}
                    />
                    <PreviewLinks html_url={html_url} subject_url={subject_url} />
                    <SendButton
                        email={this.secondary_email}
                        doc_ids={this.selectedIds}
                        urls={urls}
                        model={model}
                        gate={gate}
                    />
                    <div className={styles.margin}></div>
                    {this.showParentEmail && (
                        <div>
                            <Input
                                title="Parent Email:"
                                value={this.parent_email}
                                placeholder="Enter email address"
                                onChange={(e) => {
                                    this.parent_email = e.target.value
                                }}
                                onClear={() => {
                                    this.parent_email = ""
                                }}
                            />
                            <PreviewLinks html_url={parent_html_url} subject_url={parent_subject_url} />
                        </div>
                    )}
                    <div className={styles.inner}>
                        <div className={styles.checkboxWrapper}>
                            {pack.docs.map((doc) => {
                                let title = ""
                                if (doc.type === "set1") {
                                    title = "Other FA Documents"
                                }

                                if (doc.type === "plan") {
                                    title = "Financial Plan"
                                }

                                if (doc.type === "agreement") {
                                    title = "Enrollment Agreement"
                                }

                                return (
                                    <CheckBox
                                        key={doc.id}
                                        text={`${title} (${doc.id})`}
                                        onChange={(e) => {
                                            this.updateSelectedIds(e.target.checked, doc.id)
                                        }}
                                        checked={this.selectedIds.includes(doc.id)}
                                    />
                                )
                            })}
                        </div>
                    </div>
                    {/*<p className={styles.text}>This action cannot be undone</p>*/}
                    <div className={styles.buttonWrapper}>
                        {onClose && <PopupButton text="Cancel" onClick={onClose} />}
                        {false && (
                            <PopupButton
                                text="SEND"
                                type="secondary"
                                disabled={this.isLoading}
                                onClick={async () => {
                                    this.isLoading = true
                                    await this.onSendEmail()
                                    this.isLoading = false
                                }}
                            />
                        )}
                    </div>
                </div>
            </article>
        )

        return ReactDOM.createPortal(html, document.getElementById("appModal"))
    }
}

function PreviewLinks({html_url, subject_url}: {html_url; subject_url}) {
    return (
        <div className={styles.likWrapper}>
            <a className={styles.link} href={subject_url} target="_blank" rel="noopener noreferrer">
                Subject Preview
            </a>
            <a className={styles.link} href={html_url} target="_blank" rel="noopener noreferrer">
                Email Preview
            </a>
        </div>
    )
}
