import React, {Component} from "react"
import {api_response} from "lib/gate/interfaces"
import styles from "./DocumentTypeCell.module.css"
import {observer} from "mobx-react"
import {Link} from "react-router-dom"

type Item = api_response.Agreement | api_response.Doc | api_response.Plan

interface Props {
    item: Item
    token: string
}

@observer
export class DocumentTypeCell extends Component<Props, {}> {
    public render() {
        const {item, token} = this.props
        const url = `/docs/${item.id}?token=${token}`
        let title: string
        if (!item) {
            title = ""
        }
        if (item.type === "set1") {
            title = "Other FA Documents"
        }
        if (item.type === "agreement") {
            title = "Enrollment Agreement"
        }
        if (item.type === "plan") {
            title = "Financial Plan"
        }

        return (
            <td className={styles.cell}>
                {url && (
                    <Link className={styles.link} to={url}>
                        {title}
                    </Link>
                )}
                {!url && {title}}
            </td>
        )
    }
}
