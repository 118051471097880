import React, {Component} from "react"
import Select, {Props} from "react-select"

interface KlassDropdownProps extends Props {
    onChange: any
    options: any
    value: any
}

const customStyles = {
    control: (provided) => ({
        ...provided,
        borderRadius: "6px",
        borderColor: "transparent",
        boxShadow: "1px 2px 3px 1px var(--black-100)",
        border: "none"
    }),

    container: (provided) => ({
        ...provided,
        height: "40px"
    }),

    indicatorSeparator: () => ({
        display: "none"
    }),

    dropdownIndicator: (provided, state) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
        width: "40px",
        height: "39px",
        borderTopRightRadius: "6px",
        borderBottomRightRadius: "6px",
        backgroundColor: "var(--primary-400-base)",
        borderColor: "var(--primary-400-base)",
        justifyContent: "center",
        color: "var(--whitebase)",
        "&:hover": {color: "var(--whitebase)"},
        cursor: "pointer"
    }),

    placeholder: (provided) => ({
        ...provided,
        fontSize: "16px",
        color: "var(--black-200)"
    })
}

export class KlassDropdown extends Component<KlassDropdownProps, {}> {
    public render() {
        const props = {...this.props, styles: customStyles}

        return <Select {...props} />
    }
}
