import React, {Component} from "react"
import ReactDOM from "react-dom"
import styles from "./ChangeStatusPopup.module.css"
import classNames from "classnames"
import {DocumentStatus} from "lib/gate/interfaces"
import {observer} from "mobx-react"
import {observable} from "mobx"
import {Gate} from "lib/gate/Gate"

interface Props {
    gate: Gate
    document_id: number
    old_status: DocumentStatus
    new_status: DocumentStatus
    onClose: () => void
}

@observer
export class ChangeStatusPopup extends Component<Props, {}> {
    @observable
    private comment: string = ""

    @observable
    private isLoading = false

    public componentDidMount() {
        document.getElementById("appModal").style.display = "block"
        this.comment = ""
    }

    public componentWillUnmount() {
        document.getElementById("appModal").style.display = "none"
    }

    public render() {
        const {old_status, new_status, onClose} = this.props

        let html = (
            <article className={styles.popup}>
                <div className={styles.iconWrapper}>
                    <svg
                        className={styles.icon}
                        xmlns="http://www.w3.org/2000/svg"
                        width="39"
                        height="39"
                        viewBox="0 0 39 39">
                        <path
                            fillRule="evenodd"
                            d="M34.125 9.5H26V6.856c-.08-2.11-1.896-3.76-4.063-3.69h-4.875c-2.166-.07-3.983 1.58-4.062 3.69V9.5H4.875c-.897 0-1.625.709-1.625 1.583 0 .875.728 1.584 1.625 1.584H6.5v17.416c0 2.624 2.183 4.75 4.875 4.75h16.25c2.692 0 4.875-2.126 4.875-4.75V12.667h1.625c.897 0 1.625-.71 1.625-1.584 0-.874-.728-1.583-1.625-1.583zM16.25 25.333c0 .875-.728 1.584-1.625 1.584S13 26.207 13 25.333V19c0-.874.728-1.583 1.625-1.583s1.625.709 1.625 1.583v6.333zm0-18.477c0-.253.341-.523.813-.523h4.875c.47 0 .812.27.812.523V9.5h-6.5V6.856zM26 25.333c0 .875-.728 1.584-1.625 1.584s-1.625-.71-1.625-1.584V19c0-.874.728-1.583 1.625-1.583S26 18.126 26 19v6.333z"
                        />
                    </svg>
                </div>
                <div className={styles.body}>
                    {/*<h1 className={styles.title}>{newStatus}</h1>*/}
                    <p className={styles.text}>
                        Change status from <strong> {old_status} </strong> to <strong> {new_status} </strong>
                    </p>
                    <br />
                    Comment:
                    <textarea
                        value={this.comment}
                        onChange={(event) => {
                            this.comment = event.target.value
                        }}
                    />
                    <div className={styles.buttonWrapper}>
                        <button className={styles.button} onClick={onClose}>
                            Cancel
                        </button>

                        <button
                            disabled={this.isLoading}
                            className={classNames(styles.button, styles.secondary)}
                            onClick={async () => {
                                this.isLoading = true
                                const {gate, document_id, old_status, new_status} = this.props
                                const {data} = await gate.request(`/docs/${document_id}/change-status`, {
                                    data: {
                                        comment: this.comment,
                                        old_status,
                                        new_status
                                    }
                                })
                                this.isLoading = false
                                this.props.onClose()
                            }}>
                            OK
                        </button>
                    </div>
                </div>
            </article>
        )

        return ReactDOM.createPortal(html, document.getElementById("appModal"))
    }
}
